@import "./variables";
#login{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-image: url('../../bgseiko.png');
    background-size:cover ;
    background-color: $color-info-dark;
    

}
.container__login{
    min-width: 20%;
    height: 55%;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    color: $color-info-dark;

    h2{
        color:$color-info-light;
        font-size: 2rem;
        font-weight: 500;
        span{
            font-weight: 300;
            font-size: 2rem;
            color: $color-primary;
            
        }
    }
    form{
        background: $color-background;
        width: 100%;
        height: 70%;
        padding: 1rem 0.8rem;
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        opacity: 0.98;
        border-radius:   $border-radius-1;
        h4{
            text-align: center;
            color: $color-info-dark;
            font-size: 1.1rem;
            font-weight: 500;
        }
        label{
            color:$color-info-dark;
            font-size: 0.9rem;
            font-weight: 500;
           
        }
        .inputs{
            border: $color-info-dark 1.5px solid;
            width: 100%;
            padding: 0.5rem;
            outline: none;
            background: transparent;
            border-radius:   $border-radius-1;

        }
        .error{
            background: transparent;
            border: none;
            outline: none;
            color: $color-danger;
            text-align: center;

        }
        .nav{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0.3rem;
            h3 strong{
                font-weight: 700;
            }
            span{
                display: flex;
                gap: 0.4rem;
            }
        }
        .btn-pry{
            padding: 0.4rem 0.8rem;
            border:none;
            background-color: $color-primary;
            color: white;
            border-radius:   $border-radius-1;
            font-weight: 500;
        }
        a{
            color:$color-primary;
        }
        .misc{
            padding-top: 1rem;
        }
    }

}
@media (max-width: $breakpoint-tablet) {
    #login{
        .container__login{
            min-width: 60%; 
        }
    }
}
@import "./variables";

.detail__view{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  .cell_padding{
    padding: .5rem;
    }
    .right{

        max-width: 99%;
       overflow-y: auto;
       max-height: 100%;

      }


    .left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        max-width: 100%;
        padding:1rem;
        overflow: hidden;
        .starts{
            //width: 100%;
            justify-items: center;
            justify-content: center;
            display: flex;
            padding: 1rem 2rem;
            gap: 0.5rem;
           color: $color-info-dark;
            }
        }

    .welcome-info{
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0;
        color: $color-info-dark;
        h2{
            color:$color-dark;
        }


    }
}

@media (max-width: $breakpoint-tablet) {
    .detail__view{
        .right{
            height: 100% !important;
      
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            .emp__card1 {
                width: 16rem;
                height: 8rem;
                padding-left: 0.5rem;
            }
        }


    }
}
@media (max-width: $breakpoint-mobile) {
    .analytics__view{
        max-width: 100vw !important;
        overflow-x: hidden;
        .right{
            height: 100% !important;
            padding: 0 0.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.5rem !important;
            .emp__card1 {
                width: 12rem;
                height: 8rem;
                padding-left: 0.5rem;
                .bottom {
                    justify-self: flex-end;
                    height: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }


    }
    .left{
    .starts{
        padding: 0.5rem !important;
    }
}
}

@import "./variables";

.dashboard__view{
    height: 100%;
    width: 100%;
    //display: flex;
    flex-direction: column;
   // overflow: hidden;

  .cell_padding{
    padding: .5rem;
  }
    .right{


       //display: grid;
       //grid-template-columns: 1fr;
       //gap: 5rem;
      width: 18rem;
       //place-items: center;
       overflow-y: auto;
       max-height: 100%;

       .emp__card1 {
        //background: white;
        height: 6rem;
        border-radius: .7rem;
        display: flex;
        flex-direction: column;
        padding: 2rem;

      img {
        background-color: #14213d;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        background-size: cover;
        object-fit: cover;
      }
      .top {
        height: 50%;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-weight: 500;
        gap: 1rem;
        position: relative;
        font-size: 0.9rem;

        h3 {
          color: $color-black;
        }
      }
      .bottom {
        height: 30%;
        display: flex;
        flex-direction: row;
        margin-top: auto;
        //justify-content: space-around;
        align-items: center;

      h4 {
        font-weight: 300;
        color: $color-info-dark;
        font-size: 0.6rem;
        text-align: center;
      }
      small {
        font-size: 0.5rem;
        color: $color-primary;
        font-weight: 500;
      }
        med {
         // font-size: .7rem;
          color: $color-black;
          opacity: 100%;
         // font-weight: 500;
        }
      }

    }


      .subject__card {
        //width: 18rem;
        //background: white;
        height: 6rem;
        border-radius: 0.7rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: 100%;

        img {
          background-color: #14213d;
          border-radius: 50%;
          height: 2.5rem;
          width: 2.5rem;
          background-size: cover;
          object-fit: cover;
        }
        .top {
          height: 33%;
          align-items: center;
          display: flex;
          flex-direction: row;
          font-weight: 500;
          gap: 0.5rem;
          position: relative;
          font-size: 0.7rem;
          h3{
            display: block;
            margin: auto;
            text-align: center;
            color: $color-black;
            opacity: 100%;
            font-size: 150%;
          }

          &::after {
            content: "";
            width: 100%;
            height: 0.05rem;
            background-color: $color-dark;
            position: absolute;
            bottom: 0;
            justify-self: center;
            left: 50%;
            transform: translate(-50%, 50%);
          }}
        .middle {
          height: 33%;
          display: flex;
          flex-direction: row;
          margin-top: auto;
          //justify-content: space-around;
          align-items: center;


          h4 {
            font-weight: 300;
            color: $color-black;
            font-size: 75%;
            display: block;
            margin: auto;
            text-align: center;

          }
          small {
            // font-size: 0.5rem;
            color: $color-black;
            font-weight: 500;
            text-align: center
          }
        }
        .bottom {
          height: 33%;
          display: flex;
          flex-direction: row;
          margin-top: auto;
          //justify-content: space-around;
          align-items: center;

          h4 {
            font-weight: 300;
            color: $color-black;
            font-size: 75%;
            display: block;
            margin: auto;
            text-align: center;
          }
          small {
           // font-size: 0.5rem;
            color: $color-black;
            font-weight: 500;
            text-align: center;
          }
        }

      }
    .emp__card{
        width: 18rem;
        color: $color-info-dark;
        border-radius: $border-radius-2;
        height: 10rem;
        background: white;
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 25% auto;
        //grid-template-rows: repeat(1fr,4);
        gap:0.3rem;
        grid-template-areas: 'img info1'
        'img info2'
        'img info3'
        'img info4';
        img{
            grid-area: img;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            align-self: center;
           }
           .card-info{
            display: flex;
            border: 1px solid $color-info-dark;
            border-radius: $border-radius-1;
            font-size: 0.7em;
            align-items: center;
            gap: 0.3em;
            :first-child{
                background: $color-info-light;
                height: 100%;
                padding: 0 0.2rem;
                border-right: 1px solid $color-info-dark;
                display: flex;
                align-items: center;
            }

           }
    }




    }
    .left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        max-width: 100%;
        padding:1rem;
        overflow: hidden;
        .starts{
            //width: 100%;


         .left-items {
           justify-items: left;
           justify-content: left;
           align-items: center;
           display: flex;
           width: 25%;
           padding: 1rem 2rem;
           gap: 0.5rem;

           img {
             justify-items: left;
             justify-content: left;
             width: 40%;
             object-fit: cover;
           }
         }
          .center-items{
            justify-items: center;
            justify-content: center;
            display: flex;
            padding: 1rem 2rem;
            gap: 0.5rem;

        .starts__card{
            width: 12rem;
            height: 7rem;
            background: $color-background;
            padding: 0.6rem;
            display: flex;
            gap: 0.5rem;
            margin-right: 5rem;
            flex-direction: column;
            border-radius: $border-radius-3;
            :first-child{
                color: $color-primary;
              font-size: 10rem;

            }
          svg{

            height: 4rem;
            width: 4rem;
            color: $color-primary;
          }
            h3{
                font-size: 1.5rem;
                color: $color-dark;
            }
            small{
                font-size: 1.2rem;
                color: $color-info-dark;
            }
        }}
          .right-items{
            width: 15%;
            display: flex;
            padding: 1rem 2rem;
            gap: 0.5rem;

            img {
              justify-items: left;
              justify-content: left;
              width: 25%;
              object-fit: cover;
            }
          }

        }

        form{
            position: relative;
            padding-top: 1.5rem;
            //background: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        .search{

            position: relative;
            width: 100%;
            margin: 0 auto;
            background: transparent;
            border-radius: $border-radius-2;
            border: 2px solid $color-info-dark;
            padding: 0.5rem;
        }
        .search__icon{
            position: absolute;
            font-size: 2rem;
            margin-right: 0.5rem;
            color: $color-info-dark;
        }

    }
    .welcome-info{
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0;
        color: $color-info-dark;
        h2{
            color:$color-dark;
        }


    }
    .users-list{
        display: flex;
        flex-direction: column;
        overflow:hidden;
        .user-title{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            h3{
                color:$color-dark
            }
            select{
                outline: none;
                border-radius: $border-radius-1;
                background: transparent;
                color: $color-info-dark;
            }
        }


    }
    }
}
@media (max-width: $breakpoint-tablet) {
    .analytics__view{
        .right{
            height: 100% !important;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            .emp__card1 {
                width: 16rem;
                height: 8rem;
                padding-left: 0.5rem;
            }
        }


    }
}
@media (max-width: $breakpoint-mobile) {
    .analytics__view{
        max-width: 100vw !important;
        overflow-x: hidden;
        .right{
            height: 100% !important;
            padding: 0 0.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.5rem !important;
            .emp__card1 {
                width: 12rem;
                height: 8rem;
                padding-left: 0.5rem;
                .bottom {
                    justify-self: flex-end;
                    height: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }


    }
    .left{
    .starts{
        padding: 0.5rem !important;
    }
}
}

@import "./variables";
$alert-width: 1000px;
$alert-extra-padding: 50px;
.timecard__view{

     height: 100%;
     width: 95%;
     display: grid;
     max-width: 99%;
     //grid-template-columns: 1.5fr 1fr;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #textboxid
    {
        height: 50px;
        font-size:20pt;
        padding: 1rem;
        margin-bottom: 2rem;

    }
    #textboxid:focus
    {
        outline: none !important;
        border-color: $color-primary;
        box-shadow: 0 0 10px $color-primary;
    }
    .keypad{
        animation: fadeIn ease 0.5s;
        width: $alert-width;
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $color-timecard-background;
    }
    .btn{
        padding-left: 3rem;
        height: 50px;
        border-radius: $border-radius-3;
        font-weight: 900;
        font-size: 20pt;

    }
    .btn-new{
        background: transparent;
        border: 2px solid $color-primary;
        color: $color-info-dark;
        padding: 3rem;

    }
    .right{
        // background: grey;
         height: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 1rem;
         overflow: hidden;
         img{
             background-color: $color-info-dark;
             //border-radius: 50%;
             width: 30%;
             height: 30%;
         }
         h2{
             font-size: x-large;
             text-align: center;
             justify-content: center;
         }
        .btn{
            padding: 0.5rem 1rem;
            border-radius: $border-radius-3;
            font-weight: 700;
        }
        .btn-new{
            background: transparent;
            border: 2px solid $color-primary;
            color: $color-info-dark;

        }


         .card{

             background: lightgray;
             border-radius: $border-radius-3;
             width: 75%;
             margin: 0.5rem auto;
             height: auto;
             display: flex;
             flex-direction: column;
             justify-content: center;
             align-items: center;
             padding: 0.5rem;
             gap: 0.3rem;
             img{
                 background-color: $color-info-dark;
                 border-radius: 60%;
                 width: 8rem;
                 height: 8rem;
             }
             h3{
                 font-size: 2rem;
                 color: $color-dark;

             }


             .card-info{

                 border: 1px solid $color-info-dark;
                 border-radius: $border-radius-1;
                 width: 80%;
                 display: flex;
                 align-items: center;
                 gap: 0.2rem;
                 :first-child{
                     color: $color-dark;
                     background: $color-light;
                     padding: 0.1rem 0.2rem;
                     height: 100%;
                     border-right: 1px solid $color-info-dark;

                 }
             }

         }
         .right-manage{
             width: 100%;
             display: flex;
             justify-content: center;
             flex-direction: row;
             gap: 1rem;
             padding: 1rem;
             font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
             monospace;

             .alert-main {
                 animation: fadeIn ease 0.5s;
                 width: $alert-width;
                 margin: auto;
                 position: fixed;
                 background-color: $color-timecard-background;
                 min-height: 250px;
                 display: flex;
                 align-items: center;
                 flex-direction: column;
                 z-index: 9999;
                 top: 0vh;
                 left: calc(50vw - #{($alert-width/2)});
                 @media (max-width: $alert-width+$alert-extra-padding) {
                     width: 90vw;
                     left: 5vw;
                 }
             }

             .alert-header {
                 font-size: 26px;
                 font-weight: 600;
             }

             .alert-body {
                 text-align: center;
                 font-size: 17px;
                 display: grid;
                 //grid-template-columns: 1fr 1fr 1fr;
                 padding: 1rem 2rem;
                 gap: 2rem;
                 max-width: 100%;
                 left: calc(50vw - #{($alert-width/2)});

             }

             .alert-button {
                 width: 90px;
                 height: 50px;
                 display: flex;
                 justify-content: center;
                 text-decoration: none;
                 color: white;
                 border-radius: 40px;
                 font-size: 17px;
                 font-weight: 600;
                 border-radius: 30px;
                 align-items: center;
                 position: relative;
                 margin-top: 30px;
                 margin-bottom: 20px;
             }

             .backdrop {
                 animation: fadeIn ease 1s;
                 background-color: rgba(0, 0, 0, 0.6);
                 position: fixed;
                 left: 0;
                 top: 0;
                 width: 100vw;
                 height: 100vh;
                 z-index: 9998;
             }

             @keyframes fadeIn {
                 0% {
                     opacity: 0;
                 }
                 100% {
                     opacity: 1;
                 }
             }
             .btn{
                 padding: 0.5rem 1rem;
                 border-radius: $border-radius-3;
                 font-weight: 700;

                 &:hover{
                    transform: scale(1.1);
                 }
             }
             .btn-subject{
                 padding: 0.5rem 1rem;
                 border: 2px solid $color-primary;
                 border-radius: $border-radius-3;
                 font-weight: 700;
                 width: 100%;
                 height: 5rem;
                 background-color: transparent;

                 &:hover{
                     transform: scale(1.1);
                 }
             }
             .btn-in{
                 padding: 0.5rem 1rem;
                 border: 2px solid $color-primary;
                 border-radius: $border-radius-3;
                 font-weight: 700;
                 width: 100%;
                 color: $color-info-dark;
                 height: 5rem;

             }
             .btn-out{
                 border-radius: $border-radius-3;
                 border: none;
                 background: $color-primary;
                 color: $color-info-light;

             }
             .btn-grid{
                 //justify-content: space-between;
                 display: grid;
                 grid-template-columns: 1fr 1fr 1fr;
                 padding: 1rem 2rem;
                 gap: 2rem;
                 width: 100%;
             }
             .btn-subject-cont {
                 width: 15rem;
                 height: 4rem;
                 padding: 0.6rem;
                 gap: 0.5rem;
                 border-radius: $border-radius-3;
                 :first-child {
                     color: $color-primary;
                     font-size: 1.7rem;
                 }
             }


         }
         .forms-container{
             background: white;
             border-radius: $border-radius-3;
             flex-grow: 1;
             display: flex;
             width: 80%;
             max-height: 95%;
             flex-direction: column;
             justify-content: flex-start;
            align-items: center;
            h3{
                color: $color-dark;
                font-size: 1.2rem;
                padding: 1rem;
            }
            .timestamp-container{
                display: flex;
                width: 90%;

                flex-direction: column;
                overflow-y: auto;
                padding: 2rem;
                max-height: 27rem;
                span{
                    font-size: 0.8rem;
                    text-align: center;
                    padding: 0.3rem 0.6rem;
                    color:$color-info-dark;
                    border-radius: $border-radius-1;
                &:nth-child(odd){
                    background: $color-info-light;


                }}
            }
         }

     }
     .left{
         display: flex;
         flex-direction: column;
         position: relative;
         max-height: 100%;
         padding:1rem;
         overflow: hidden;
         .starts{
             width: 100%;
             justify-content: space-between;
             display: flex;
             padding: 0.8rem;
             gap: 0.5rem;
         .starts__card{
             width: 12rem;
             height: 7rem;
             background: white;
             padding: 0.6rem;
             display: flex;
             gap: 0.5rem;
             flex-direction: column;
             border-radius: $border-radius-3;
             :first-child{
                 color: $color-primary;
                 font-size: 1.7rem;
             }
             h3{
                 color: $color-dark;
             }
             small{
                 color: $color-info-dark;
             }
         }}

         form{
             position: relative;
             padding-top: 1.5rem;
             //background: #fff;
             display: flex;
             align-items: center;
             justify-content: flex-end;
         .search{

             position: relative;
             width: 100%;
             margin: 0 auto;
             background: transparent;
             border-radius: $border-radius-2;
             border: 2px solid $color-info-dark;
             padding: 0.5rem;
         }
         .search__icon{
             position: absolute;
             font-size: 2rem;
             margin-right: 0.5rem;
             color: $color-info-dark;
         }

     }
     .welcome-info{
         display: flex;
         flex-direction: column;
         margin: 1.5rem 0;
         color: $color-info-dark;
         h2{
             color:$color-dark;
         }


     }
     .users-list{
         display: flex;
         flex-direction: column;
         overflow:hidden;
         .user-title{
             display: flex;
             justify-content: space-between;
             margin: 1rem 0;
             padding: 0.8rem;
             h3{
                 color:$color-dark
             }
             select{
                 outline: none;
                 border-radius: $border-radius-1;
                 background: transparent;
                 color: $color-info-dark;
             }
         }
         .results{
             background: white;
             border-radius: $border-radius-3;
             padding: 1rem;
             display: flex;
             flex-direction: column;
             overflow-y: auto;
             height: auto;
             max-height: 45rem;
             width: 100%;
             span{
                 padding: 0.3rem;
                 font-weight: 500;
                 width: 98%;
                 margin: 0.2rem auto;
                 color: $color-info-dark;
                 border-radius: $border-radius-1;
                 &:hover{
                     border:2px solid $color-primary;

                 }
                 small{
                     opacity: 0.9;
                 }
             }

         }

     }
     }
 }
 @media (max-width: $breakpoint-tablet) {
    .timecard__view{

         grid-template-columns: 1fr;
         overflow: auto;
    }
}

@import "src/components/scss/variables";

.analytics__view{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //place-items: center;
  // grid-template-rows: 1.5fr 1fr;
  overflow: hidden;
    .right{
       // background: grey;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr;
       gap: 1rem;
       place-items: center;
       overflow-y: auto;
       max-height: 32rem;

       .emp__card1 {
        width: 18rem;
        background: white;
        height: 6rem;
        border-radius: 0.7rem;
        display: flex;
        flex-direction: column;
        padding: 0.3rem;
      
      img {
        background-color: #14213d;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        background-size: cover;
        object-fit: cover;
      }
      .top {
        height: 85%;
        align-items: center;
        display: flex;
        flex-direction: row;
        font-weight: 500;
        gap: 0.5rem;
        position: relative;
        font-size: 0.7rem;
        h3{
            color: $color-dark;
        }
      
      &::after {
        content: "";
        width: 80%;
        height: 0.05rem;
        background-color: $color-dark;
        position: absolute;
        bottom: 0;
        justify-self: center;
        left: 50%;
        transform: translate(-50%, 50%);
      }}
      .bottom {
        justify-self: flex-end;
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      
      h4 {
        font-weight: 300;
        color: $color-info-dark;
        font-size: 0.6rem;
      }
      small {
        font-size: 0.5rem;
        color: $color-primary;
        font-weight: 500;
      } }  

    }
    .emp__card{
        width: 18rem;
        color: $color-info-dark;
        border-radius: $border-radius-2;
        height: 10rem;
        background: white;
        padding: 0.5rem;
        display: grid;
        grid-template-columns: 25% auto;
        grid-template-rows: repeat(1fr,4);
        gap:0.3rem;
        grid-template-areas: 'img info1'
        'img info2'
        'img info3'
        'img info4';
        img{
            grid-area: img;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            align-self: center;
           }
           .card-info{
            display: flex;
            border: 1px solid $color-info-dark;
            border-radius: $border-radius-1;
            font-size: 0.7em;
            align-items: center;
            gap: 0.3em;
            :first-child{
                background: $color-info-light;
                height: 100%;
                padding: 0 0.2rem;
                border-right: 1px solid $color-info-dark;
                display: flex;
                align-items: center;    
            }
            
           }
    }
       
       
    

    }
    .left{
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      max-width: 100%;
      padding:1rem;
      overflow: hidden;
        .starts{
          justify-items: center;
          justify-content: center;
          display: flex;
          padding: 1rem 2rem;
          gap: 0.5rem;
        .starts__card{
          width: 12rem;
          height: 7rem;
          background: white;
          padding: 0.6rem;
          display: flex;
          gap: 0.5rem;
          margin-right: 5rem;
          flex-direction: column;
            border-radius: $border-radius-3;
            :first-child{
                color: $color-primary;
                font-size: 1.7rem;
            }
            h3{
                color: $color-dark;
            }
            small{
                color: $color-info-dark;
            }
        }}

        form{
            position: relative;
            padding-top: 1.5rem;
            //background: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        .search{
            
            position: relative;
            width: 100%;
            margin: 0 auto;
            background: transparent;
            border-radius: $border-radius-2;
            border: 2px solid $color-info-dark;
            padding: 0.5rem;
        }
        .search__icon{
            position: absolute;
            font-size: 2rem;
            margin-right: 0.5rem;
            color: $color-info-dark;
        }
    
    }
    .welcome-info{
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0;
        color: $color-info-dark;
        h2{
            color:$color-dark;
        }


    }
    .users-list{
        display: flex;
        flex-direction: column;
        overflow:hidden;    
        .user-title{
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            h3{
                color:$color-dark
            }
            select{
                outline: none;
                border-radius: $border-radius-1;
                background: transparent;
                color: $color-info-dark;
            }
        }


    }
    }
}
@media (max-width: $breakpoint-tablet) {
    .dashboard__view{
        .right{
      
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            .emp__card1 {
                width: 16rem;
                height: 8rem;
                padding-left: 0.5rem;
            }
        }


    }
}
@media (max-width: $breakpoint-mobile) {
    .dashboard__view{
        .right{
      
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            .emp__card1 {
                width: 16rem;
                height: 8rem;
                padding-left: 0.5rem;
            }
        }


    }
}
@media (max-width: $breakpoint-mobile) {
    .dashboard__view{
        max-width: 100vw !important;
        overflow-x: hidden;
        .right{
            height: 100% !important;
            padding: 0 0.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.5rem !important;
            .emp__card1 {
                width: 12rem;
                height: 8rem;
                padding-left: 0.5rem;
                .bottom {
                    justify-self: flex-end;
                    height: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }


    }
    .left{
    .starts{
        padding: 0.5rem !important;
    }
}
}
